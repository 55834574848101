import { useState } from "react";
import DataCategories from "./components/categories/DataCategories";
import LogoImage from "./top-40-under-40-ventures-logo.png";
import "./App.css";
function App() {
	const [votes, setVotes] = useState("");
	const [code, setCode] = useState("");
	const [phonenumber, setPhonenumber] = useState("");
	return (
		<div className="container">
			<div className="mheader container">
				<div className="row align-items-center">
					{/* Home link */}
					<div className="col-12 col-lg-4 text-lg-start text-center mb-3 mb-lg-0">
						<a href="https://top40under40ventures.co.ke/" className="p-3">
							Home
						</a>
					</div>

					{/* Logo Image */}
					<div className="col-12 col-lg-4 text-center">
						<a href="https://top40under40ventures.co.ke/">
							<img
								src={LogoImage}
								alt="logo"
								className="img-fluid rounded"
								width={200}
							/>
						</a>
					</div>
				</div>
			</div>
			<h3 className="text-center">Embu Top 40 Under 40 - 2024</h3>
			<div className="text-center">
				<p>
					The Embu Top 40 Under 40 Awards is an annual initiative spearheaded by
					the Embu Young Professionals in conjunction with Embu Buzz to
					recognize and celebrate the achievements of young individuals in Embu
					County who are making significant contributions across various
					sectors. The awards aim to honor those under the age of 40 who have
					demonstrated exceptional talent, leadership, and commitment in fields
					such as politics, business, arts and music, media, sports, innovation,
					community service, education, family, religion, and agriculture.
				</p>
				<p>
					The objective of the awards is to Celebrate Excellence; to identify
					and honor young people in Embu County who are excelling in their
					respective fields and making a positive impact on the community and
					inspire the next generation by recognizing role models for other young
					people in the region by showcasing the success stories of their peers.
				</p>
				<p>
					For enquiries please contact us via email:
					<a href="mailto:embutop40under40@gmail.com">
						embutop40under40@gmail.com
					</a>{" "}
					or tel <a href="tel:+254704284546">+254704284546</a>
				</p>

				<p>
					To vote via USSD Dial *250*24# Enter Nominee Code then follow prompts
					Voting ends 10th October.
				</p>

				<p> You can vote as many times as possible.</p>
			</div>
			<div className="row">
				<div className="col-lg-6 col-sm-12">
					<div className="p-3">
						<h3>Vote online here</h3>
						<p>Enter code, No. of votes then proceed.</p>
						<div className="row">
							<div className="col-lg-6 col-sm-12">
								<input
									type="text"
									className="form-control p-3 mb-3 shadow-sm"
									style={{ border: "2px solid #ced4da", borderRadius: "5px" }}
									placeholder="code"
									value={code}
									onChange={(e) => setCode(e.target.value)}
									required={true}
								/>
							</div>
							<div className="col-lg-6 col-sm-12">
								<input
									type="text"
									className="form-control p-3 mb-3 shadow-sm"
									style={{ border: "2px solid #ced4da", borderRadius: "5px" }}
									placeholder="votes"
									value={votes}
									onChange={(e) => setVotes(e.target.value)}
									required
								/>
							</div>
							<div id="proceedBtn" className="p-3 d-block">
								<button
									className="form-control"
									style={{
										backgroundColor: "#ebc334",
										borderRadius: "5px",
									}}
									data-toggle="collapse"
									href={`#collapsenext`}
									aria-expanded="false"
									aria-controls={`collapsenext`}
								>
									Proceed
								</button>
							</div>
							<div className="p-3 collapse" id={`collapsenext`}>
								<div>
									<p className="bg-secondary p-3">
										Vote for your favourite nominee in Embu Top 40 Under 40 -
										2024 @ KES 10.00/= per Vote
									</p>
									<p className="bg-secondary p-3">Enter number of votes</p>
								</div>
								<button
									className="form-control"
									style={{
										backgroundColor: "#ebc334",
										borderRadius: "5px",
									}}
									data-toggle="collapse"
									href={`#collapsevote`}
									aria-expanded="false"
									aria-controls={`collapsevote`}
								>
									Next
								</button>
							</div>

							<div className="p-3 collapse" id="collapsevote">
								<input
									type="text"
									className="form-control p-3 mb-3 shadow-sm d-block"
									style={{ border: "2px solid #ced4da", borderRadius: "5px" }}
									placeholder="mpesa number"
									value={phonenumber}
									onChange={(e) => setPhonenumber(e.target.value)}
									required
								/>

								<button
									className="form-control"
									style={{
										backgroundColor: "#ebc334",
										borderRadius: "5px",
									}}
									onClick={(e) => {
										e.preventDefault();
										alert("Thank you for voting!");
									}}
								>
									Vote
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-6 col-sm-12">
					<div className="p-3">
						<button
							className="form-control"
							style={{
								backgroundColor: "#ebc334",
								borderRadius: "5px",
							}}
						>
							Click on category to view nominee Codes
						</button>
						<div>
							<DataCategories />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
