import React from "react";

const DataCategories = () => {
	const categorydata = [
		{
			category: "POLITICS(MALE)",
			tabledata: [
				{
					name: "John Gitonga Mukunji - MP Manyatta Constituency",
					code: "2101",
				},
				{
					name: "Eric Karemba - MP Runyenjes Constituency",
					code: "2102",
				},
				{
					name: "Pithon Muchoki - 2022 UDA senatorial Candidate and current Chief Officer sports, Creative Arts and Talent Development",
					code: "2103",
				},
				{
					name: "Muchangi Mwariama - MCA Kagaari North",
					code: "2104",
				},
				{
					name: "Stephen Munene Simba - Former MCA Runyenjes Central",
					code: "2105",
				},
				{
					name: "Paul Muchangi Njiru - MCA Gaturi North",
					code: "2106",
				},
				{
					name: "Collo Morris - Former MCA Kîrîmarî and 2022 Manyatta MP Aspirant",
					code: "2107",
				},
				{
					name: "Humphrey Njiru - Aspiring MP - Mbeere South",
					code: "2108",
				},
				{
					name: "Samy Tito - MCA Nthawa",
					code: "2109",
				},
				{
					name: "Duncan Muratia Nyaga (Mukangu) - Aspiring MCA",
					code: "2110",
				},
				{
					name: "Charles Mwaura (Marvo) - Aspiring MCA Mbeti North",
					code: "2111",
				},
				{
					name: "Frederick Mugendi (Kamwigunyi) - MCA Gaturi South",
					code: "2112",
				},
				{
					name: "Lenny Master Kariuki - MCA Kiambere",
					code: "2113",
				},
				{
					name: "Loy Mutembei (Leftie) - 2022 Kirimari MCA Aspirant",
					code: "2114",
				},
				{
					name: "Edwin Kamuti - MCA Runyenjes Central",
					code: "2115",
				},
			],
		},
		{
			category: "POLITICS(FEMALE)",
			tabledata: [
				{
					name: "Susan Mwende - Nominated MCA",
					code: "2201",
				},
				{
					name: "Susan Gitari - 2022 Woman Rep Aspirant",
					code: "2202",
				},
				{
					name: "Carol Mbae - Politician",
					code: "2203",
				},
				{
					name: "Teresia Kaari - Kagaari South 2022 MCA Aspirant",
					code: "2204",
				},
				{
					name: "Terry Mbuchi - MCA Kyeni North",
					code: "2205",
				},
				{
					name: "Winnie Rodgers - Runyenjes Constituency Manager",
					code: "2206",
				},
				{
					name: "Irene Njeri (Mkombozi) - 2022 MCA Aspirant Mbeti North and member of the Governor Delivery Unit",
					code: "2207",
				},
				{
					name: "Grace Murugi Gichovi - UDA Director Events and Logistics",
					code: "2208",
				},
				{
					name: "Esther Mukami Mwaniki - Aspiring Woman Rep 2027",
					code: "2209",
				},
				{
					name: "Maureen Mukami - Chief Officer Marketing and Investment",
					code: "2210",
				},
				{
					name: "Maureen Nyaga - Nominated MCA",
					code: "2211",
				},
				{
					name: "Murugi Kariuki - Senator at the Youth Senate",
					code: "2212",
				},
				{
					name: "Fridah Kariuki (Dafreen Mamake) - Politician",
					code: "2213",
				},
				{
					name: "Luceta Gacheri - Chief Officer Youth Empowerment",
					code: "2214",
				},
				{
					name: "Teresia Kaari - Aspiring MCA Kagaari South",
					code: "2215",
				},
				{
					name: "Milly Kentis Njagih - Representative at the Youth Senate",
					code: "2216",
				},
			],
		},
		{
			category: "BUSINESS(MALE)",
			tabledata: [
				{
					name: "Kenneth Ngai - Director Kenstar",
					code: "2301",
				},
				{
					name: "Ken Java Mwaniki - Eagle 1",
					code: "2302",
				},
				{
					name: "Charles Shanyaki - Director FoodPlug",
					code: "2303",
				},
				{
					name: "James Karani - CEO Smart Corn",
					code: "2304",
				},
				{
					name: "David Nyaga - Director Witan",
					code: "2305",
				},
				{
					name: "Brian Waziri - Embu Sport House",
					code: "2306",
				},
				{
					name: "Moses Nguru - Business man",
					code: "2307",
				},
				{
					name: "Read Njiru Mzee Gichuki - Kumbu Clan Welding",
					code: "2308",
				},
				{
					name: "Loy Mutembei - Director Leftie Collection",
					code: "2309",
				},
				{
					name: "Dominic - Director Snack Kona",
					code: "2310",
				},
				{
					name: "Samy Nguru - Techno Shop",
					code: "2311",
				},
				{
					name: "Elder Martin - Elder M Marketing Consultancy",
					code: "2312",
				},
				{
					name: "Ken Wanga - Director Audio Pro",
					code: "2313",
				},
				{
					name: "Davis Wamae Gititio - Nanazi Gardens",
					code: "2314",
				},
				{
					name: "Edward Edu - CEO Milele Sounds and Electronic",
					code: "2315",
				},
				{
					name: "James Chomba Mugo - Director Corsica Bar & Restaurant",
					code: "2316",
				},
			],
		},
		{
			category: "BUSINESS(FEMALE)",
			tabledata: [
				{
					name: "Tanya Ireri - Azuri Designs",
					code: "2401",
				},
				{
					name: "Frida - Frika Designs",
					code: "2402",
				},
				{
					name: "Christine Nyaga - CEO Eternity Travellers",
					code: "2403",
				},
				{
					name: "Ruth John - CEO Tansan Adventures",
					code: "2404",
				},
				{
					name: "Diana - Liada Chemist",
					code: "2405",
				},
				{
					name: "Dolly Patron - Nonstick Sufurias",
					code: "2406",
				},
				{
					name: "Loise Jewel - Pishori Rice Supplier",
					code: "2407",
				},
				{
					name: "Rose Wangari - Wangari ni Waembu Cereals",
					code: "2408",
				},
				{
					name: "Maureen Mukami - Marketing and Branding Expert",
					code: "2409",
				},
				{
					name: "Faith Nyaga - Fruit Vendor",
					code: "2410",
				},
				{
					name: "Jacqueline Njeri - Cosmetics",
					code: "2411",
				},
				{
					name: "Olive Wambui - Business Lady",
					code: "2412",
				},
				{
					name: "Carol Mbugua - CEO Zanetti Gents Fashion",
					code: "2413",
				},
				{
					name: "Linda Nyakio - CEO, Malian Agribusiness Solutions",
					code: "2414",
				},
				{
					name: "Rosemary Kanambiu - Jorowafa Collection",
					code: "2415",
				},
			],
		},
		{
			category: "ARTS AND MUSIC(MALE)",
			tabledata: [
				{
					name: "Pius Njue Gpiizo - Musician",
					code: "2501",
				},
				{
					name: "Martin Gacungi",
					code: "2502",
				},
				{
					name: "Moses Nyaga - Musician",
					code: "2503",
				},
				{
					name: "Charles Kimenjo - Emcee",
					code: "2504",
				},
				{
					name: "Kelvin Gachima Overseer - Emcee",
					code: "2505",
				},
				{
					name: "Kenah - Hype Man",
					code: "2506",
				},
				{
					name: "Kenrene - Filmmaker",
					code: "2507",
				},
				{
					name: "Tony Tahidi - Akili ya Babu",
					code: "2508",
				},
				{
					name: "Zack - Exbeexy Dance Crew",
					code: "2509",
				},
				{
					name: "Erick Tipis - Karaoke Host",
					code: "2510",
				},
				{
					name: "Deejay Izrael - Deejay",
					code: "2511",
				},
				{
					name: "BOG - Mcee",
					code: "2512",
				},
				{
					name: "Charles Mwaura (Marvo) - Musician and Producer",
					code: "2513",
				},
				{
					name: "Hope - Producer",
					code: "2514",
				},
				{
					name: "Elijah Otieno - Theatre Producer",
					code: "2515",
				},
			],
		},
		{
			category: "ARTS AND MUSIC(FEMALE)",
			tabledata: [
				{
					name: "Noni Kariuki - Model",
					code: "2601",
				},
				{
					name: "Sharon Mtoto Wa Mama - Musician",
					code: "2602",
				},
				{
					name: "Marion Tesh - Musician",
					code: "2603",
				},
				{
					name: "Mumbi Georgia - Musician",
					code: "2604",
				},
				{
					name: "Njoki Wa Njiru - Actress",
					code: "2605",
				},
				{
					name: "Purity Mutua - Musician",
					code: "2606",
				},
				{
					name: "Frida Mso - Musician",
					code: "2607",
				},
				{
					name: "Anita - Actress",
					code: "2608",
				},
				{
					name: "Brenda Murugi - Actress",
					code: "2609",
				},
				{
					name: "Ashley Murugi - Filmmaker",
					code: "2610",
				},
				{
					name: "Doro Thee - Poet",
					code: "2611",
				},
				{
					name: "Zaynah Murungu - KTN TV Host",
					code: "2612",
				},
				{
					name: "Nyakio Crochets - Arts",
					code: "2613",
				},
				{
					name: "Deejay Caril - Deejay",
					code: "2614",
				},
			],
		},
		{
			category: "MEDIA(MALE)",
			tabledata: [
				{
					name: "Dn Makenge - Kiviaviano",
					code: "2701",
				},
				{
					name: "Murimi Kamwendia - Wîmwaro FM",
					code: "2702",
				},
				{
					name: "Martin Njeru - KBC",
					code: "2703",
				},
				{
					name: "Gitonga Wa Nyaga - Mwendani FM",
					code: "2704",
				},
				{
					name: "Kelvin Gachima (Overseer) - Tigai Twarie",
					code: "2705",
				},
				{
					name: "Muriithi Triple M - Kimuri",
					code: "2706",
				},
				{
					name: "Charles Kimenjo - EBN",
					code: "2707",
				},
				{
					name: "Sir Robiih - Uvoro TV",
					code: "2708",
				},
				{
					name: "Tony Tahidi - Akili ya Babu",
					code: "2709",
				},
				{
					name: "Crispine Muthii - Embu Newspaper",
					code: "2710",
				},
				{
					name: "Ken Rio Amasha",
					code: "2711",
				},
				{
					name: "Eliud Njirusky - Mukami wa Embu",
					code: "2712",
				},
				{
					name: "Murimi Wa Gichobi - Mwendani FM",
					code: "2713",
				},
				{
					name: "Dennis Fundi - Kameme TV",
					code: "2714",
				},
				{
					name: "Mlipa - Mwendani FM",
					code: "2715",
				},
				{
					name: "Osman - Osman TV",
					code: "2716",
				},
				{
					name: "Dennis Munene (Ofisa) - Ngemi FM",
					code: "2717",
				},
			],
		},
		{
			category: "MEDIA(FEMALE)",
			tabledata: [
				{
					name: "Karìmì Maggie - Wîmwaro FM",
					code: "2801",
				},
				{
					name: "Mwende Wa Muriithi",
					code: "2802",
				},
				{
					name: "Karry Wa Kamwithi",
					code: "2803",
				},
				{
					name: "Phey Emma",
					code: "2804",
				},
				{
					name: "Polina Laughter - Ngemi FM",
					code: "2805",
				},
				{
					name: "Muthoni Wa Nyaga - Mwendani FM",
					code: "2806",
				},
				{
					name: "Jack Fire Fire - KIMURI FM",
					code: "2807",
				},
				{
					name: "Mbuya Wa Ngari - Ngemi",
					code: "2808",
				},
				{
					name: "Rita Mwaniki - Werû TV",
					code: "2809",
				},
				{
					name: "Njoki Wa Njiru",
					code: "2810",
				},
				{
					name: "Anita - UTV",
					code: "2811",
				},
				{
					name: "Brenda Murugi - Boda Show",
					code: "2812",
				},
				{
					name: "Rita Muthoni - Head of Content at Arise Media",
					code: "2813",
				},
				{
					name: "Mwalimu Racheal - Radio Host",
					code: "2814",
				},
			],
		},
		{
			category: "RELIGION(MALE)",
			tabledata: [
				{
					name: "Pst. Silas Gitonga Mugo",
					code: "2901",
				},
				{
					name: "Pst. Robert Njue Namu",
					code: "2902",
				},
				{
					name: "Pst. Fundi Wasie - Full Gospel",
					code: "2903",
				},
				{
					name: "Rev. David Murimi - ACK",
					code: "2904",
				},
				{
					name: "Rev. Humphrey Njagih - ACK",
					code: "2905",
				},
				{
					name: "Rev. Moses Muriithi - ACK",
					code: "2906",
				},
				{
					name: "Apostle Antony Nyaga - Ephatha",
					code: "2907",
				},
				{
					name: "Pst. Moses Njogu",
					code: "2908",
				},
				{
					name: "Fr. David Njeru - Catholic",
					code: "2909",
				},
				{
					name: "Msafiri - Mwendani FM",
					code: "2910",
				},
				{
					name: "Pst. Eston Ita",
					code: "2911",
				},
				{
					name: "Pst. Martin Muriithi - JCM Embu",
					code: "2912",
				},
				{
					name: "Pastor Justin Njeru",
					code: "2913",
				},
			],
		},
		{
			category: "RELIGION(FEMALE)",
			tabledata: [
				{
					name: "Rev. Lily",
					code: "3001",
				},
				{
					name: "Joyce Muia",
					code: "3002",
				},
				{
					name: "Pst. Nancy Gideon - Christian Revelation Temple",
					code: "3003",
				},
				{
					name: "Rev. Nancy",
					code: "3004",
				},
				{
					name: "Rev. Daisy Mukami",
					code: "3005",
				},
				{
					name: "Pastor Rachel Newton - RGC MANYATTA",
					code: "3006",
				},
				{
					name: "Hellen Njoki Njiru - Pioneer Woman Travail International Movement and The Glory Assembly Church",
					code: "3007",
				},
			],
		},
		{
			category: "AGRICULTURE(MALE)",
			tabledata: [
				{
					name: "Eric Mutwiri Kariuki - Renowned Farmer",
					code: "3101",
				},
				{
					name: "Lewis Waweru Njeru - Renowned Farmer",
					code: "3102",
				},
				{
					name: "Martin Mutwiri (Elder Martin) - Chairman Ask",
					code: "3103",
				},
				{
					name: "David Gitonga - CEO Edited Facta",
					code: "3104",
				},
				{
					name: "Kennedy - CEO Mburugu Dairy Farmers coop Society",
					code: "3105",
				},
				{
					name: "Abdallah Mbogo - Kombucha",
					code: "3106",
				},
				{
					name: "Elias Kivuti Nyaga - Ashels Farm",
					code: "3107",
				},
				{
					name: "James Karani - CEO Smart Corn",
					code: "3108",
				},
				{
					name: "Raphael Ngari Mukangu - Horticulturalist",
					code: "3109",
				},
				{
					name: "Joseph Kinyua - Josmu Farm",
					code: "3110",
				},
				{
					name: "Sammy Gitonga - Village Vet",
					code: "3111",
				},
				{
					name: "Dennis Mugendi - Poultry Farmer",
					code: "3112",
				},
				{
					name: "Stephen Juma - Renowned Farmer",
					code: "3113",
				},
				{
					name: "Earnest - Kamiu Nursery",
					code: "3114",
				},
				{
					name: "Alex Njagi(Alekii) - Renowned Farmer",
					code: "3115",
				},
				{
					name: "Elijah Mwirigi - CEO Exodus Star Limited",
					code: "3116",
				},
				{
					name: "Elijah Nyagah Ireri - Muguka Farmer",
					code: "3117",
				},
			],
		},
		{
			category: "AGRICULTURE(FEMALE)",
			tabledata: [
				{
					name: "Linda Nyakio - CEO Malian Agribusiness Solutions",
					code: "3201",
				},
				{
					name: "Charity Wanjiku - Hoftor Macadamia Nuts",
					code: "3202",
				},
				{
					name: "Nelly Njoki - Cleo Nature",
					code: "3203",
				},
				{
					name: "Lucy Karimi - Luken Honey",
					code: "3204",
				},
				{
					name: "Ciku Njeru - Kanji Farm",
					code: "3205",
				},
				{
					name: "Ann Nyaga - Former CAS Agriculture, Livestock, Fisheries, and Cooperatives",
					code: "3206",
				},
				{
					name: "Christine Mwende - Guinea Pig Farmer",
					code: "3207",
				},
				{
					name: "Olive Muriithi - Mazao na Afya Ltd",
					code: "3208",
				},
				{
					name: "Faith Mwinamo - Renowned Farmer",
					code: "3209",
				},
			],
		},
		{
			category: "SPORTS(MALE)",
			tabledata: [
				{
					name: "Kenneth Murimi",
					code: "3301",
				},
				{
					name: "Francis Chege - Coach (3k FC)",
					code: "3302",
				},
				{
					name: "Washington Munene - footballer (AFC Leopards)",
					code: "3303",
				},
				{
					name: "Weston - Footballer (Ishiara FC)",
					code: "3304",
				},
				{
					name: "Ken Santos - Coach (CAF A)",
					code: "3305",
				},
				{
					name: "Jeremiah Maringa",
					code: "3306",
				},
				{
					name: "Peter Chuma - Marshal Fitness Kenya",
					code: "3307",
				},
				{
					name: "Charles Njagi - Chairman Fkf Embu Branch",
					code: "3308",
				},
				{
					name: "Morris Munene Gachaga - Gold Medalist China",
					code: "3309",
				},
				{
					name: "Hussein Feisal - FIFA Accredited Agent",
					code: "3310",
				},
				{
					name: "Ben Macharia - Mixed Martial Arts",
					code: "3311",
				},
				{
					name: "Benson Mwenda - Coach (All Saints Embu High School)",
					code: "3312",
				},
				{
					name: "Jeremy - Skating Trainer",
					code: "3313",
				},
				{
					name: "Brian Munene Njue (Mwanaspoti Nene) - Sports Journalist",
					code: "3314",
				},
				{
					name: "Bosire - Embu Rugby Union",
					code: "3315",
				},
				{
					name: "Kocha Morrey - Sports Commentator",
					code: "3316",
				},
			],
		},
		{
			category: "SPORTS(FEMALE)",
			tabledata: [
				{
					name: "Hellen Wawira - Paralympic Weight Lifter",
					code: "3401",
				},
				{
					name: "Joan Murugi - Paralympic Weight Lifter",
					code: "3402",
				},
				{
					name: "Caroline Nyaga - Gold Medalist (African Senior Athletics)",
					code: "3403",
				},
				{
					name: "Hellen Njeri - FKF Accredited Referee",
					code: "3404",
				},
				{
					name: "Nyahe Maureen",
					code: "3405",
				},
				{
					name: "Margie Nthautho - Coach (Musa Otieno Academy)",
					code: "3406",
				},
				{
					name: "Caroline - Chess Trainer",
					code: "3407",
				},
				{
					name: "Purity Daviest - Sports Commentator",
					code: "3408",
				},
				{
					name: "Trizah Musyoka - Licensed Football Referee",
					code: "3409",
				},
				{
					name: "Pauline Gakeni - Reporter & Commentator",
					code: "3410",
				},
				{
					name: "Elizabeth Muturi - KPL Referee",
					code: "3411",
				},
				{
					name: "Feriloy - FKF Referee",
					code: "3412",
				},
			],
		},
		{
			category: "COMMUNITY SERVICE(MALE)",
			tabledata: [
				{
					name: "Ephantus Muriithi (Fanta) - Weekend Plan Ushers",
					code: "3501",
				},
				{
					name: "Ken Java Mwaniki - Eagle 1",
					code: "3502",
				},
				{
					name: "Jacob Mbao - Mbao Foundation",
					code: "3503",
				},
				{
					name: "Duncan Murimi - Gifted Hand",
					code: "3504",
				},
				{
					name: "Gilbert Njeru - Bush Getaways",
					code: "3505",
				},
				{
					name: "Ken Maina (Kenito) - Philanthropist",
					code: "3506",
				},
				{
					name: "Lewis Shiru - Philanthropist",
					code: "3507",
				},
				{
					name: "Nicholas Kasanga - MWEMA Youth Alliance (MYA)",
					code: "3508",
				},
				{
					name: "Chief Jackson Kamau",
					code: "3509",
				},
				{
					name: "Saleh Gitonga (Saleh Clarissa) - Teacher, Writer, and Philanthropist",
					code: "3510",
				},
			],
		},
		{
			category: "COMMUNITY SERVICE(FEMALE)",
			tabledata: [
				{
					name: "Victoria Ngina Muia - Philanthropist",
					code: "3601",
				},
				{
					name: "Esther Mukami Mwaniki - Philanthropist",
					code: "3602",
				},
				{
					name: "Rosenta Ndolo - Philanthropist",
					code: "3603",
				},
				{
					name: "Olive Wambui (Auntie wa CDs)",
					code: "3604",
				},
				{
					name: "Ashley Murugi - Philanthropist",
					code: "3605",
				},
				{
					name: "Nancy Ngina - Community Worker",
					code: "3606",
				},
				{
					name: "Susan (Sussy) - Community Worker",
					code: "3607",
				},
				{
					name: "Rev. Lily - Rev Lily Foundation",
					code: "3608",
				},
				{
					name: "Lynn Gathoni - Founder Girl Up Kenya",
					code: "3609",
				},
				{
					name: "Carol Bells - A Cry for Orphans",
					code: "3610",
				},
				{
					name: "Sharon Njeri - Community Health Worker",
					code: "3611",
				},
			],
		},
		{
			category: "INNOVATION(MALE)",
			tabledata: [
				{
					name: "Abdallah Mbogo - Kombucha",
					code: "3701",
				},
				{
					name: "Philip Muraya - Fikra Branding",
					code: "3702",
				},
				{
					name: "Kombo Nyaga - Katya Natures",
					code: "3703",
				},
				{
					name: "Kelvin Muriithi Mwaniki - Amy Designs",
					code: "3704",
				},
				{
					name: "Timothy - Jaba Waba",
					code: "3705",
				},
				{
					name: "Pius Wambua - SowPrecise Africa",
					code: "3706",
				},
				{
					name: "Charles Shanyaki - FoodPlug",
					code: "3707",
				},
				{
					name: "Stephen Mwangi Maina - String Arts",
					code: "3708",
				},
				{
					name: "Mistri - Corsica Bar and Restaurant",
					code: "3709",
				},
				{
					name: "Mbogo and Muchangi - Shoe Makers",
					code: "3710",
				},
				{
					name: "Jim Waweru - Desert Film",
					code: "3711",
				},
				{
					name: "John Ngugi - Coffee Maker",
					code: "3712",
				},
				{
					name: "Obama Davido MG - Leather Shoe Maker",
					code: "3713",
				},
				{
					name: "Martin Martoh - Neillah Barbers",
					code: "3714",
				},
			],
		},
		{
			category: "INNOVATION(FEMALE)",
			tabledata: [
				{
					name: "Tanya Ireri - Azuri Designs",
					code: "3801",
				},
				{
					name: "Nyakio Crotchet",
					code: "3802",
				},
				{
					name: "Frida Ngugi - Frika Designs",
					code: "3803",
				},
				{
					name: "Linda Nyakio - Malian Agribusiness Solutions",
					code: "3804",
				},
				{
					name: "Sety Ndwiga - Sety's Decor and Gift Shop",
					code: "3805",
				},
				{
					name: "Catherine Nyawira Njoki - Cate wa Kimere",
					code: "3806",
				},
				{
					name: "Daisy Mukami - Daisy Mobile Porridge Restaurant",
					code: "3807",
				},
			],
		},
		{
			category: "FAMILY",
			tabledata: [
				{
					name: "Lewis Murimi Nthiga and Shiru",
					code: "3901",
				},
				{
					name: "Thee Fundis (Winnie & Linus)",
					code: "3902",
				},
				{
					name: "The Rogers (Winnie and Rogers)",
					code: "3903",
				},
				{
					name: "Rev. Moses and Ruth",
					code: "3904",
				},
				{
					name: "The Bravery's",
					code: "3905",
				},
				{
					name: "The Njagis (Njagi & Carol)",
					code: "3906",
				},
				{
					name: "The Sagongo's (Josphat Sagongo and Wife)",
					code: "3907",
				},
				{
					name: "The Dans (Nice Gacharia and Menge Dan)",
					code: "3908",
				},
				{
					name: "The Kioko's (Kioko and Grace)",
					code: "3909",
				},
				{
					name: "The Donians (Dommie and Diana)",
					code: "3910",
				},
				{
					name: "The Kobuthis (Martin Kobuthi and Kellen)",
					code: "3911",
				},
				{
					name: "The Allans (Apostle Antony Allan and Lily Tony)",
					code: "3912",
				},
				{
					name: "The Humphreys (Rev. Humphrey and Carol)",
					code: "3913",
				},
				{
					name: "The Masters (Lenny Mwaniki)",
					code: "3914",
				},
				{
					name: "The Gilberts (Gilbert and Shelmith)",
					code: "3915",
				},
				{
					name: "The Eduhs (MC Edward Murimi and Wife)",
					code: "3916",
				},
				{
					name: "The DNKs (DNK and Mjay)",
					code: "3917",
				},
				{
					name: "The Ceasers (Ceaser and Julie)",
					code: "3918",
				},
			],
		},
		{
			category: "EDUCATION(MALE)",
			tabledata: [
				{
					name: "Saleh Clarissa - Teacher and Writer",
					code: "4001",
				},
				{
					name: "Miu Salesius Njoka - Director Brookmont School",
					code: "4002",
				},
				{
					name: "Paul Kiragu - Director Embu College",
					code: "4003",
				},
				{
					name: "Elijah Otieno",
					code: "4004",
				},
				{
					name: "Brian Idakala",
					code: "4005",
				},
				{
					name: "Kelvin Munene - Teacher, Mbui Njeru Secondary",
					code: "4006",
				},
				{
					name: "Josphat Ndwiga (Sagongo) - Teacher",
					code: "4007",
				},
				{
					name: "Willington Mwiti - Mbiruri Teacher",
					code: "4008",
				},
				{
					name: "Frederick Okwacho - Kangaru School",
					code: "4009",
				},
				{
					name: "Eric Njenga - Millbrook International School",
					code: "4010",
				},
			],
		},
		{
			category: "EDUCATION(FEMALE)",
			tabledata: [
				{
					name: "Nashebanda Jean Viva",
					code: "4101",
				},
				{
					name: "Purity Wanyaga - Teacher",
					code: "4102",
				},
				{
					name: "Rose Wangari - Wangari ni Waembu - Teacher",
					code: "4103",
				},
				{
					name: "Bessy - Teacher Kanyuambora",
					code: "4104",
				},
				{
					name: "Purity Daviest - Teacher and Commentator",
					code: "4105",
				},
				{
					name: "Wanja wa Kavengi - Writer",
					code: "4106",
				},
			],
		},
	];

	return (
		<div className="row pt-3">
			{categorydata.map((data) => (
				<div key={data.tabledata[1].code} className="col-md-6 col-12">
					<div key={data.tabledata[1].code}>
						<p className="text-capitalize">
							<a
								className="text-white"
								data-toggle="collapse"
								href={`#collapse${data.tabledata[1].code}`}
								aria-expanded="false"
								aria-controls={`collapse${data.tabledata[1].code}`}
							>
								{data.category}
							</a>
						</p>
						<div className="collapse" id={`collapse${data.tabledata[1].code}`}>
							<table>
								<thead>
									<tr>
										<td>Nominee</td>
										<td>Code</td>
									</tr>
								</thead>
								<tbody>
									{data.tabledata.map((mdata) => (
										<tr key={data.code}>
											<td>{mdata.name}</td>
											<td>{mdata.code}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default DataCategories;
